<template>
  <div class="home">
    <Banner
      :src="require('@/assets/images/banner-inner01.png')"
      :active="true"
    ></Banner>
    <div class="main">
      <div class="section">
        <div class="title">
          <img :src="imgs.title" alt="" />
          <img class="append" :src="imgs.circles" alt="" />
        </div>
        <div class="group modules">
          <div class="item"><img :src="imgs.car" alt="" /></div>
          <div class="item"><img :src="imgs.driver" alt="" /></div>
          <div class="item"><img :src="imgs.platform" alt="" /></div>
          <div class="item"><img :src="imgs.technology" alt="" /></div>
        </div>
        <Wings class="info info-1">
          <template v-slot:left>
            <div class="pic">
              <img :src="imgs.pic01" alt="" />
            </div>
          </template>
          <template v-slot:right>
            <div class="text">
              <div class="pre">Cars、Drivers、Platform、Technology</div>
              <div class="l-prepend-1 normal main">
                汽车、司机、平台、科技配置
              </div>
              <div class="l-append"></div>
              <div class="l-prepend-2 normal">
                森淼科技在汽车金融、司机管理、平台搭建不断深耕
              </div>
              <div class="l-prepend-3 normal">
                通过科技赋能逐步形成三者的有机融合发展
              </div>
            </div>
          </template>
        </Wings>
        <Wings class="info info-2">
          <template v-slot:left>
            <div class="text">
              <div class="pre">cooperative partner</div>
              <div class="r-prepend-1 normal main">合作伙伴</div>
              <div class="r-append"></div>
              <div class="r-prepend-2 normal">众志成城，同舟共济</div>
              <div class="r-prepend-3 normal">携手共建公共出行生态圈</div>
            </div>
          </template>
          <template v-slot:right>
            <div class="pic"><img :src="imgs.pic02" alt="" /></div>
          </template>
        </Wings>
        <div class="group roles">
          <div class="item" @mouseenter="handleMouseenter">
            <div class="img">
              <img :src="imgs.pic00" alt="" />
            </div>
            <div class="item-text">
              <div class="text-title">战略投资方</div>
              <div class="text-normal">
                为公共出行提供强大的资源与信任背书，共推公共出行生态圈。
              </div>
            </div>
          </div>
          <div class="item">
            <div class="img">
              <img :src="imgs.pic00" alt="" />
            </div>
            <div class="item-text">
              <div class="text-title">网约车平台</div>
              <div class="text-normal">
                基于网约车平台，将运力最大化、高效投入公共出行领域。
              </div>
            </div>
          </div>
          <div class="item">
            <div class="img">
              <img :src="imgs.pic00" alt="" />
            </div>
            <div class="item-text">
              <div class="text-title">运力服务商</div>
              <div class="text-normal">
                汇聚车辆运力管理、司机管理，提供司机服务能力与新车安全。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "./Banner";
import Wings from "./Wings";
export default {
  components: {
    Banner,
    Wings,
  },
  data() {
    return {
      imgs: {
        title: require("@/assets/images/ihongsen.png"),
        circle01: require("@/assets/images/circle01.png"),
        circles: require("@/assets/images/circles.png"),
        car: require("@/assets/images/car.png"),
        driver: require("@/assets/images/driver.png"),
        platform: require("@/assets/images/platform.png"),
        technology: require("@/assets/images/technology.png"),
        pic01: require("@/assets/images/pic01.png"),
        pic02: require("@/assets/images/pic02.png"),
        pic00: require("@/assets/images/pic00.jpg"),
      },
    };
  },
  created() {},
  methods: {
    handleMouseenter() {},
  },
};
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  background: url("~@/assets/images/foot.png") no-repeat bottom;
  .main {
    max-width: 1280px;
    margin: 0 auto;
    .section {
      .title {
        display: flex;
        justify-content: center;
        position: relative;
        padding: 40px 0;
        .append {
          position: absolute;
          right: 60px;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
      .group {
        display: flex;
        justify-content: center;
        max-width: 100%;
        margin-left: 10px;
        margin-right: 10px;
        .item {
          user-select: none;
          flex: 0 1 auto;
        }
      }
      .group.modules {
        margin-top: 25px;
        margin-bottom: 25px;
        .item:nth-of-type(n + 2) {
          margin-left: 34px;
        }
        .item {
          width: 260px;
          padding: 22px 0;
          background: #ffffff;
          box-shadow: 0px 6px 27px 0px rgba(27, 116, 255, 0.09);
          border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            max-width: 100%;
            border: none;
          }
        }
      }
      .group.roles {
        margin-top: 90px;
        padding-bottom: 83px;
        .img {
          max-width: 100%;
          height: 50%;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          position: relative;
          background: #e8f1ff no-repeat center / contain;
          img {
            position: absolute;
            max-width: 150%;
            top: 0;
            bottom: 0;
            margin: auto;
            border-radius: 10px;
            visibility: hidden;
          }
        }

        .item:nth-of-type(n + 2) {
          margin-left: 30px;
        }
        .item {
          max-width: 350px;
          height: 354px;
          background: #ffffff;
          border-radius: 10px;
          &:hover {
            animation: pulse 0.8s;
          }
          &:nth-of-type(1) .img {
            background-image: url("~@/assets/images/pic03.png");
          }
          &:nth-of-type(2) .img {
            background-image: url("~@/assets/images/pic04.png");
          }
          &:nth-of-type(3) .img {
            background-image: url("~@/assets/images/pic05.png");
          }
          .item-text {
            padding: 20px 48px;
            font-size: 16px;
            .text-title {
              text-align: left;
              color: #181818;
              font-size: 1.5em;
            }
            .text-normal {
              margin-top: 0.5em;
              line-height: 2em;
              text-align: left;
              color: #666666;
              font-size: 1em;
            }
          }
          @media screen and (max-width: 860px) {
            .item-text {
              padding: 15px 36px;
            }
          }
          @media screen and (max-width: 774px) {
            .item-text {
              padding: 10px 24px;
            }
          }
          @media screen and (max-width: 700px) {
            .item-text {
              padding: 5px 12px;
              font-size: 15px;
            }
          }
        }
      }
      .info {
        .pic {
          max-width: 100%;
          img {
            width: 100%;
          }
        }
        .text {
          & > * {
            width: 100%;
            margin-bottom: 0.5em;
          }
          .pre {
            color: #adccff;
            font-size: 24px;
          }
          .normal {
            color: #666666;
            font-size: 18px;
          }
          .main {
            color: #171717;
            font-size: 32px;
          }
          .l-append,
          .r-append {
            &:after {
              content: "";
              display: block;
              width: 100%;
              height: 4px;
              font-size: 32px;
              margin-bottom: 0.5em;
              border-radius: 2px;
              background: url("~@/assets/images/jx.png") no-repeat;
            }
          }
          .l-append {
            &:after {
              background-position: left center;
            }
          }
          .r-append {
            &:after {
              background-position: right center;
            }
          }
          [class*="prepend-"] {
            position: relative;
            &::before {
              content: "";
              display: block;
              width: 10px;
              height: 10px;
              position: absolute;
              top: 0;
              bottom: 0;
              margin: auto 0;
            }
          }
          [class*="l-prepend"] {
            &::before {
              left: -20px;
            }
          }
          [class*="r-prepend"] {
            &::before {
              right: -20px;
            }
          }
          [class*="prepend-1"] {
            &::before {
              background: url("~@/assets/images/circle01.png") no-repeat center
                center;
            }
          }
          [class*="prepend-2"] {
            &::before {
              background: url("~@/assets/images/circle02.png") no-repeat center
                center;
            }
          }
          [class*="prepend-3"] {
            &::before {
              background: url("~@/assets/images/circle03.png") no-repeat center
                center;
            }
          }
        }
      }
      .info-1 {
        .text {
          text-align: left;
          margin-left: 20px;
          padding-right: 10px;
        }
      }
      .info-2 {
        .text {
          margin-right: 20px;
          text-align: right;
        }
      }
    }
  }
}
</style>
