<template>
  <div class="wings" ref="wings">
    <div
      :class="{
        'wing-left': true,
        'animate__animated animate__fadeInLeft': isInViewport,
      }"
      ref="wingLeft"
    >
      <slot name="left"></slot>
    </div>
    <div
      :class="{
        'wing-right': true,
        'animate__animated animate__fadeInRight': isInViewport,
      }"
      ref="wingRight"
    >
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hasScrolled: false,
      isInViewport: false,
    };
  },
  created() {
    this.listenerFunction();
  },
  destroyed() {
    this.stopListenerFunction();
  },
  methods: {
    listenerFunction(e) {
      document.addEventListener("scroll", this.handleScroll, true);
    },
    stopListenerFunction() {
      document.removeEventListener("scroll", this.handleScroll,true);
    },
    handleScroll() {
      if (this.hasScrolled) return;
      const topDistance = this.$refs.wings.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      const elemHeight = this.$refs.wings.clientHeight;
      this.isInViewport =
        topDistance <= windowHeight && topDistance >= 0 - elemHeight;
      // this.hasScrolled = true;
    },
  },
};
</script>

<style lang="less" scoped>
.wings {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  .wing-left,
  .wing-right {
    width: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
